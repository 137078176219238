.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
}

.modal-explain {
  display: flex;
  max-width: 350px;
  height: 50px;
  padding: 8px 20px;
  margin: 10px auto;
  font-size: 14px;
  text-align: center;
}

.modal-enjoy {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 30;
  display: flex;
  max-width: 350px;
  padding: 20px;
  font-size: 14px;
  text-align: center;
  transform: translate(50%, -50%);
}

.modal-close {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  width: 200px;
  height: 60px;
  font-weight: bold;
}

.modal-close-button {
  width: 100%;
}
